import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './partials/sidebar/sidebar.component';
import { HeaderComponent } from './partials/header/header.component';
import { NavsearchComponent } from './partials/header/navsearch/navsearch.component';
import { OffsidebarComponent } from './partials/offsidebar/offsidebar.component';
import { UserblockComponent } from './partials/sidebar/userblock/userblock.component';
import { UserblockService } from './partials/sidebar/userblock/userblock.service';
import { FooterComponent } from './partials/footer/footer.component';
import { CalendarComponent } from './pages/booking/prenotationCalendar/prenotationCalendar.component';
import { SharedModule } from '../shared/shared.module';
import { BookingComponent } from './pages/booking/booking.component';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import {  AgGridModule } from 'ag-grid-angular';
import { MatExpansionModule, MatNativeDateModule, MatRippleModule, MatInputModule, MatFormFieldModule, MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        SharedModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatButtonModule,
        HttpClientModule,
        FullCalendarModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatNativeDateModule,
        MatExpansionModule,
        AgGridModule.withComponents([])
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent,
        BookingComponent,
        CalendarComponent
        
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent
    ]
})
export class LayoutModule { }
