import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BoatLessonQueueControllerService {
    private url = environment.booking_apiUrl + 'boat-lessons';

  constructor() {}

  queueBoatLessonPost(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/queue', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  queueBoatLessonDelete(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/queue', {
      method: 'delete',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

      return fetch(richiesta);
      
  }

  ReservedBoatPost(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/reserved', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta);
  }

  ReservedBoatDelete(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/reserved', {
      method: 'delete',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

      return fetch(richiesta);
      
  }
}
