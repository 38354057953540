import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeachersControllerService {
    private url = environment.booking_apiUrl + 'teachers';

  constructor() {}

  teachersCount(where: object, token: string) {
    if (where == null) {
      var richiesta = new Request(this.url + '/count' + where, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    } else {
      var richiesta = new Request(this.url + '/count?where=' + where, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    }

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  teacherPut(
    id: number,
    deleted_at: Date,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    telegram_challenge: string,
    telegram_chat_id: string,
    token: string
  ) {
    if (deleted_at != null) {
      var richiesta = new Request(this.url + '/' + id, {
        method: 'put',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          deleted_at: deleted_at,
          id: id,
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          telegram_challenge: telegram_challenge,
          telegram_chat_id: telegram_chat_id
        })
      });
    } else {
      var richiesta = new Request(this.url + '/' + id, {
        method: 'put',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          id: 0,
          first_name: 'string',
          last_name: 'string',
          email: 'string',
          phone: 'string',
          telegram_challenge: 'string',
          telegram_chat_id: 0
        })
      });
    }

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  teacherPatch(id: number, first_name: string, last_name: string, email: string, phone: string, telegram_challenge: string, telegram_chat_id: string, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'patch',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        //"deleted_at": "2019-09-06T08:08:55.497Z",
        //"id": 0,
        first_name: 'string',
        last_name: 'string',
        email: 'string',
        phone: 'string',
        telegram_challenge: 'string',
        telegram_chat_id: 0
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  teacherGet(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta);
  }

  teacherDelete(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'delete',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  teachersPost(id: number, first_name: string, last_name: string, email: string, phone: string, telegram_challenge: string, telegram_chat_id: number, token: string) {
    var richiesta = new Request(this.url, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        //"deleted_at": "2019-09-06T08:08:55.497Z",
        //"id": 0,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        telegram_challenge: telegram_challenge,
        telegram_chat_id: telegram_chat_id
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
        response.json().then(res => {
          console.log(res);
        });
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  teachersPatch(
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    telegram_challenge: string,
    telegram_chat_id: string,
    where: object,
    token: string
  ) {
    if (where == null) {
      var richiesta = new Request(this.url, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          //"deleted_at": "2019-09-06T08:08:55.497Z",
          //"id": 0,
          first_name: 'string',
          last_name: 'string',
          email: 'string',
          phone: 'string',
          telegram_challenge: 'string',
          telegram_chat_id: 0
        })
      });
    } else {
      var richiesta = new Request(this.url + '?where=' + where, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          //"deleted_at": "2019-09-06T08:08:55.497Z",
          //"id": 0,
          first_name: 'string',
          last_name: 'string',
          email: 'string',
          phone: 'string',
          telegram_challenge: 'string',
          telegram_chat_id: 0
        })
      });
    }

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  teachersGet(filter: object, token: string): Promise<any> {
    if (filter == null) {
      var richiesta = new Request(this.url, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    } else {
      var richiesta = new Request(this.url + '?filter=' + filter, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    }

    return fetch(richiesta);
  }
}
