import { OnInit, Inject } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

import itLocale from '@fullcalendar/core/locales/it.js';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { SettingsControllerService } from '../services/settingsController/settings-controller.service';
import { BoatLessonControllerService } from '../services/boatController/boatLessonController/boat-lesson-controller.service';
import { AgGridModule } from 'ag-grid-angular'
import { TeachersControllerService } from '../services/teachersController/teachers-controller.service';
import { BoatLessonStudentControllerService } from '../services/boatController/boatLessonStudentController/boat-lesson-student-controller.service';
import { BoatLessonQueueControllerService } from '../services/boatController/boatLessonQueueController/boat-lesson-queue-controller.service';
import { SendMessagesControllerService } from '../services/sendMessagesController/send-messages-controller.service';
import { StudentsBoatLessonControllerService } from '../services/studentsController/studentsBoatLessonController/students-boat-lesson-controller.service';
import { StudentsControllerService } from '../services/studentsController/studentsController/students-controller.service';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
/*
'../../../../bootstrap/dist/css/bootstrap.min.css'
*/

@Component({
    selector: 'app-calendar',
    templateUrl: './prenotationCalendar.component.html',
    styleUrls: ['./prenotationCalendar.component.css'
        ]
})

export class CalendarComponent implements OnInit {

    @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template
    popOverVisible = false;
    calendarVisible = true;
    calendarPlugins = [dayGridPlugin, interactionPlugin];
    calendarWeekends = true;
    calendarEvents: EventInput[] = []; //eventi presenti al caricamento dal db
    addStudentsQueue = false;

  date = '';
  idLPrenotate: Array<number> = [];
  idLInCoda: Array<number> = [];
  prenotazione;
  inCoda;
  Modifica = false;
  studentiQueue: any=[];
  studentiReserved:  Array<any>=[];
  seats: number;
  teachers: Array<{ first_name: string, id: number, last_name: string, email: string, phone: string, telegram_challenge: string, telegram_id: number }> = [];
  reservedColor: { reserved: string, list: string, none: string } = { reserved: '', list: '', none: ''};
  primaryColor: { max: string, min: string, color: string };
  secondaryColor: { max: string, min: string, color: string };
  tertiaryColor: { max: string, min: string, color: string };
  checkoutForm = new FormGroup({
    thisDate: new FormControl(''),
    time: new FormControl(''),
    capitani: new FormControl(''),
    messaggioBroadcast: new FormControl(''),
    newDate: new FormControl(''),
    newTime: new FormControl(''),
      newCapitani: new FormControl(''),
   
   // topic: new FormControl(''),
  //  topicEditable: new FormControl([])
  });
  formVisualizza = new FormGroup({
    prenotato: new FormControl([]),
    inAttesa: new FormControl([])
  });
  token = '';
  skipperSelected: FormControl = new FormControl();
  skipperSelectedNew: FormControl = new FormControl();
  level = '';
  capitano = '';
  topic: any = {
    "prenotati": 0,
    "inCoda": 0

    }

    modifiedEvent: boolean = false;
    skipperLesson: string;
    //thisTopic: string;
    idLastClickedEvent = ''; //id ultimo evento cliccato
    lastClickedEvent = ''; //ultimo evento cliccato
    lengthReserved: number; //lunghezza per evento della coda dei prenotati
    lengthQueue: number;//lunghezza per evento della coda in attesa
    infoChanged = false; //permette la visualizzazione dell'input nuova ora se si sta settando un nuovo giorno
    selectedSkipper: string;
    addStudents: boolean = false;
    studenti = [];

    columnDefs = [
        { headerName: ' ', field: 'check', suppressMovable: true, filter: false, checkboxSelection: true, resizable: true, width: 30 },
        { headerName: 'Cognome', field: 'last_name', suppressMovable: true,  sort:'asc', sortable: true, filter: false, resizable: true, width: 120 },
        { headerName: 'Nome', field: 'first_name', suppressMovable: true,  sort: 'asc', sortable: true, filter: false, resizable: true, width: 120 }
    ];

    rowDataReserved: any = [];
    rowDataQueue: Array<any> = [];
    //domLayout: 'autoHeight',suppressColumnVirtualisation: true ,suppressSizeToFit: true
    gridOptionsReserved: any = {
        columnDefs: this.columnDefs,
        rowSelection: 'multiple',
        suppressHorizontalScroll: true


    };
    gridOptionsQueue: any = {
        columnDefs: this.columnDefs,
        rowSelection: 'multiple',
        suppressHorizontalScroll: true


    };





  constructor(private colorSettings: SettingsControllerService,
    private boatController: BoatLessonControllerService,
    private teacherController: TeachersControllerService,
    private boatStudentsController: BoatLessonStudentControllerService,
    private router: Router,
    private boatQueueController: BoatLessonQueueControllerService,
      private sendMessageController: SendMessagesControllerService,
      private studentsController: StudentsControllerService,
      private studentController: StudentsBoatLessonControllerService,
      @Inject(LOCAL_STORAGE) private storage: StorageService) {
      
      if ((this.storage.get('token') != null) &&
          (((this.storage.get('user').levels[0] == 'Admin')
              || (this.storage.get('user').levels[0] == 'Teacher')
              || (this.storage.get('user').levels[0] == 'Student')))) {

          this.token = this.storage.get('token');
          this.level = this.storage.get('user').levels[0];


          this.modifiedEvent = false;
          this.addStudents = false;
          this.addStudentsQueue = false;
         
          this.calendarController();


      }
      else {
        //  this.router.navigateByUrl('login');
      }
  }
    
  takeTeacher() {
    if (this.level == 'Admin' || this.level == 'Teacher') {
      this.teacherController.teachersGet(null, this.token).then((response: any) => {
          response.json().then(res => {
             
          this.teachers = res;
         
        })
      }).catch(error => {
        console.log("Errore take teacher ");
        window.location.replace('error');
      });
    }
  }

    ngOnInit() {

        this.takeTeacher();

    }




    takeBoatLesson() {

        this.boatController.boatLessonGet(null, this.token).then((response: any) => {
            response.json().then(res => {
                this.calendarEvents = res;

                for (let key in res) {
                    this.calendarEvents[key].title = res[key].count_reserved + ' Prenotati';
                    this.calendarEvents[key].description = res[key].count_reserved + ' prenotati ' + res[key].count_queued + ' in lista' + ' capitano: ' + res[key].teacher_first_last_name;
                }
                this.changeBackgroundEvent();
            })
        }).catch(error => {
            console.log("Errore take boat lessons ");
            window.location.replace('error');
        });
    }



    changeBackgroundEvent() { //assegna il colore di background 
        let i: number;
        let postiDisponibili: number;

        if (this.level == 'Student') {
            for (i = 0; i < this.calendarEvents.length; i++) {

                postiDisponibili = this.seats - this.calendarEvents[i].count_reserved;
                ///

                if (((parseInt(this.primaryColor.min)) <= (postiDisponibili)) &&
                    ((postiDisponibili) <= (parseInt(this.primaryColor.max)))) {


                    this.calendarEvents[i].backgroundColor = this.primaryColor.color;

                }
                else if (((parseInt(this.secondaryColor.min)) <= (postiDisponibili)) &&
                    ((postiDisponibili) <= (parseInt(this.secondaryColor.max)))) {


                    this.calendarEvents[i].backgroundColor = this.secondaryColor.color;
                }
                else if (((parseInt(this.tertiaryColor.min)) <= (postiDisponibili)) &&
                    ((postiDisponibili) <= (parseInt(this.tertiaryColor.max)))) {

                    this.calendarEvents[i].backgroundColor = this.tertiaryColor.color;
                }
                if (this.idLInCoda.includes(parseInt(this.calendarEvents[i].id.toString()))) {

                    this.calendarEvents[i].backgroundColor = this.reservedColor.list;

                } else if (this.idLPrenotate.includes(parseInt(this.calendarEvents[i].id.toString()))) {

                    this.calendarEvents[i].backgroundColor = this.reservedColor.reserved;

                }
                ///
            }
        }
        else {

            for (i = 0; i < this.calendarEvents.length; i++) {

                postiDisponibili = this.seats - this.calendarEvents[i].count_reserved;
                ///

                if (((parseInt(this.primaryColor.min)) <= (postiDisponibili)) &&
                    ((postiDisponibili) <= (parseInt(this.primaryColor.max)))) {


                    this.calendarEvents[i].backgroundColor = this.tertiaryColor.color;


                }
                else if (((parseInt(this.secondaryColor.min)) <= (postiDisponibili)) &&
                    ((postiDisponibili) <= (parseInt(this.secondaryColor.max)))) {


                    this.calendarEvents[i].backgroundColor = this.secondaryColor.color;
                }
                else if (((parseInt(this.tertiaryColor.min)) <= (postiDisponibili)) &&
                    ((postiDisponibili) <= (parseInt(this.tertiaryColor.max)))) {

                    this.calendarEvents[i].backgroundColor = this.primaryColor.color;
                }

                ///
            }
            if (localStorage.getItem('duplicateEvent')) {
                this.showDuplicateEvent();
            }
        }

    }

    showDuplicateEvent() {
        let duplicatedEvent: any = JSON.parse(localStorage.getItem('duplicateEvent'));

        this.checkoutForm.reset;
        this.infoChanged = false;
        this.Modifica = true; /////// 
        this.idLastClickedEvent = duplicatedEvent.id;
        this.lastClickedEvent = duplicatedEvent;
        this.selectedSkipper = duplicatedEvent.teacher_id;
        this.capitano = duplicatedEvent.teacher_first_last_name;
        // this.thisTopic = duplicatedEvent.topic;

        this.boatController.boatLessonGetById(duplicatedEvent.id, this.token).then(response => {
            response.json().then(result => {


                //this.checkoutForm.controls["topicEditable"].setValue(result.topic);
                this.date = (new Date(result.date)).toString();

                let newDate: any = document.getElementById("newDate");
                newDate.valueAsDate = new Date(result.date);


                this.checkoutForm.controls["newDate"].setValue(newDate.value);
                this.checkoutForm.controls["newTime"].setValue((new Date(result.date)).toLocaleTimeString());
                this.checkoutForm.controls["messaggioBroadcast"].setValue('');
                this.skipperSelectedNew.patchValue(this.selectedSkipper);


                this.boatStudentsController.getBoatLessonStudentsById(duplicatedEvent.id, this.token).then(response => {
                    response.json().then(res => {
                        this.studentiQueue = res.queued;
                        this.studentiReserved = res.reserved;
                    })
                }).catch(error => {
                    console.log("Errore show event on click admin 1");
                    window.location.replace('error');
                });

                document.getElementById("createEditLesson").style.display = "block";
                document.getElementById("appCalendar").classList.add("noClick");

            })
        }).catch(error => {
            console.log("Errore show event on click admin 1");
            window.location.replace('error');
        });
        localStorage.removeItem('duplicateEvent');
    }

    calendarController() {
        if (this.level == 'Student') {
            this.takeLists();
        }

        this.takeColor();
    }



    showEventOnClick(info: any) {
        if (this.level != 'Admin') { //popOver per User e teacher 
            this.inCoda = null;
            this.prenotazione = null;
            if (this.idLInCoda.includes(parseInt(info.event.id.toString()))) {

                this.inCoda = 'inCoda';
            } else if (this.idLPrenotate.includes(parseInt(info.event.id.toString()))) {

                this.prenotazione = 'prenotazione';
            }
            let popOver = document.getElementById("popOverInfo");
            let minDisplay: number = 763;
            if (screen.width > minDisplay) {
                if (info.jsEvent.pageX > window.innerWidth * 2 / 3) {
                    popOver.style.display = "block";
                    popOver.style.left = (info.jsEvent.pageX - (document.getElementById("popOverInfo").clientWidth) - (document.getElementById("sideBar").clientWidth)) + 'px';
                    popOver.style.top = (info.jsEvent.pageY - (document.getElementById("navBar").clientHeight)) + 'px';
                    popOver.style.borderRadius = "4px 0px 16px 8px"
                    console.log("2/3");
                } else {
                    popOver.style.display = "block";
                    popOver.style.left = (info.jsEvent.pageX - (document.getElementById("sideBar").clientWidth)) + 'px';
                    popOver.style.top = (info.jsEvent.pageY - (document.getElementById("navBar").clientHeight)) + 'px';
                    popOver.style.borderRadius = "0px 4px 8px 16px"
                    console.log(" 2");
                }
            }
            else {
                if (info.jsEvent.pageX > window.innerWidth * 2 / 3) {
                    popOver.style.display = "block";
                    popOver.style.left = (info.jsEvent.pageX - document.getElementById("popOverInfo").clientWidth) + 'px';
                    popOver.style.top = (info.jsEvent.pageY - (document.getElementById("navBar").clientHeight)) + 'px';
                    popOver.style.borderRadius = "4px 0px 16px 8px"
                    console.log("2/3");
                } else {
                    popOver.style.display = "block";
                    popOver.style.left = (info.jsEvent.pageX) + 'px';
                    popOver.style.top = (info.jsEvent.pageY - (document.getElementById("navBar").clientHeight)) + 'px';
                    popOver.style.borderRadius = "0px 4px 8px 16px"
                    console.log(" 2");
                }
            }
            this.idLastClickedEvent = info.event.id;
            this.lastClickedEvent = info.event.extendedProps;
            this.skipperLesson = info.event.extendedProps.teacher_first_last_name;
            this.boatController.boatLessonGetById(info.event.id, this.token).then(response => {
                response.json().then(res => {
                    //  this.thisTopic = res.topic
                    this.lengthReserved = res.count_reserved
                    this.lengthQueue = res.count_queued;
                })
            }).catch(error => {
                console.log("Errore show event on click user");
                window.location.replace('error');
            });


        }
        else { //visualizzazione per Admin Edit/Delete/Duplicate

            this.checkoutForm.reset;
            this.infoChanged = false;
            this.Modifica = true; /////// 
            this.idLastClickedEvent = info.event.id;
            this.lastClickedEvent = info.event.extendedProps;
            this.selectedSkipper = info.event.extendedProps.teacher_id;
          
            this.capitano = info.event.extendedProps.teacher_first_last_name;
          
            // this.thisTopic = info.event.extendedProps.topic;

            this.boatController.boatLessonGetById(info.event.id, this.token).then(response => {
                response.json().then(result => {


                    //  this.checkoutForm.controls["topicEditable"].setValue(result.topic);
                    this.date = (new Date(result.date)).toString();

                    let newDate: any = document.getElementById("newDate");
                    newDate.valueAsDate = new Date(result.date);

                   
                    this.checkoutForm.controls["newDate"].setValue(newDate.value);
                    this.checkoutForm.controls["newTime"].setValue((new Date(result.date)).toLocaleTimeString());
                    this.checkoutForm.controls["messaggioBroadcast"].setValue('');

                    
                    this.skipperSelectedNew.patchValue(this.selectedSkipper);
                   
                    this.boatStudentsController.getBoatLessonStudentsById(info.event.id, this.token).then(response => {
                        response.json().then(res => {
                            this.studentiQueue = res.queued;
                            this.studentiReserved = res.reserved;

                        })
                    }).catch(error => {
                        console.log("Errore show event on click admin 1");
                        window.location.replace('error');
                    });

                    document.getElementById("createEditLesson").style.display = "block";
                    document.getElementById("appCalendar").classList.add("noClick");

                })
            }).catch(error => {
                console.log("Errore show event on click admin 1");
                window.location.replace('error');
            });

        }
    }

    closePopOverInfo() {
        document.getElementById("popOverInfo").style.display = "none";
    }





    handleDateClick(arg) {
      
        if (this.level == 'Admin') {
            this.Modifica = false;
            if (confirm('Vuoi aggiungere un nuovo evento il ' + arg.dateStr + ' ?')) {

                this.date = arg.dateStr;
                document.getElementById("createEditLesson").style.display = "block";
                document.getElementById("appCalendar").classList.add("noClick");
                let elTime: any = document.getElementById("time");
                let elCapitani: any = document.getElementById("capitani");
                //  let elTopicT: any = document.getElementById("topicT");
                if (elTime != null) {
                    elTime.value = '';
                    elTime.style.borderColor = null;
                }
                if (elCapitani != null) {
                    elCapitani.value = '';
                    elCapitani.style.borderColor = null;
                }
                /*  if (elTopicT != null) {
                    elTopicT.value = "";
                    elTopicT.style.borderColor = null;
                  }*/

            }

        }
    }
    onChangeInfo() {
        this.infoChanged = true;
    }

    duplicateEvent(createEditForm: any) { //da fare

        let date: string;
        let time: string;
        let idTeacherNew: number;
        if (this.skipperSelectedNew.value != null) {
            idTeacherNew = this.skipperSelectedNew.value;
        }
        else {
            idTeacherNew = parseInt(createEditForm.newCapitani) + 1;
        }

        time = createEditForm.newTime;
        date = createEditForm.newDate;
        let dateTim = new Date(date.concat(' ' + time));
        // let topic = createEditForm.topicEditable;

        let reservedId = this.takeReservedId();
        let queueId = this.takeQueueId();

        this.boatController.boatLessonPost('topic', dateTim, this.seats, "string", parseInt(idTeacherNew.toString()), true, this.token).then(response => {
            response.json().then(res => {

                this.boatStudentsController.patchBoatLessonStudentsById(res.id, reservedId, queueId, this.token);
                document.getElementById("appCalendar").classList.remove("noClick");
                document.getElementById("createEditLesson").style.display = "none";
                alert("Evento duplicato");
                localStorage.setItem("duplicateEvent", JSON.stringify(res));

                window.location.reload();
            })
        }).catch(error => {
            console.log("Errore duplicate event");
            window.location.replace('error');
        });


    }
    takeQueueId() {
        let i: number
        let queueId: number[] = [];
        for (i = 0; i < this.studentiQueue.length; i++) {
            queueId[i] = this.studentiQueue[i].id;
        }

        return queueId;
    }
    takeReservedId() {
        let i: number
        let reservedId: number[] = [];
        for (i = 0; i < this.studentiReserved.length; i++) {
            reservedId[i] = this.studentiReserved[i].id;
        }

        return reservedId;
    }
    editEventController(createEditForm: any) {
        let newDate: any = createEditForm.newDate;
        let newTime: any = createEditForm.newTime;
        //let thisTopic: any = createEditForm.topicEditable;
        let idTeacherNew: number;
        if (this.skipperSelectedNew.value != null) {
            idTeacherNew = this.skipperSelectedNew.value;
        }
        else {
            idTeacherNew = parseInt(createEditForm.newCapitani) + 1;
        }


        let dateTim: Date;
        let date: string = newDate;
        let time: string = newTime;
        dateTim = new Date(date.concat(' ' + time));
        this.boatController.boatLessonPutById(parseInt(this.idLastClickedEvent), 'topic', dateTim, this.seats, "string", parseInt(idTeacherNew.toString()), true, this.token)
            .then(response => {

                response.json().then(res => {

                })
            }).catch(error => {
                console.log("Errore edit event controller");
                window.location.replace('error');
            });
        document.getElementById("appCalendar").classList.remove("noClick");
        window.location.replace('booking');
    }


    createEventController(createEditForm: any) {
        document.getElementById("time").style.borderColor = null;
        document.getElementById("capitani").style.borderColor = null;
        //document.getElementById("topicT").style.borderColor = null;

        let id: number;

        id = this.skipperSelected.value;


        if (createEditForm.time == "" || id == null) {
            if (createEditForm.time == "") {
                document.getElementById("time").classList.add("error");
                document.getElementById("time").style.borderColor = '#ff4c4c';
                setTimeout(function () {
                    document.getElementById("time").classList.remove('error');
                }, 300);
            }
            if (id == null) {
                document.getElementById("capitani").classList.add("error");
                document.getElementById("capitani").style.borderColor = '#ff4c4c';
                setTimeout(function () {
                    document.getElementById("capitani").classList.remove('error');
                }, 300);
            }
            /*if(createEditForm.topic==""){
              document.getElementById("topicT").classList.add("error");
              document.getElementById("topicT").style.borderColor = '#ff4c4c';
              setTimeout(function() {
                document.getElementById("topicT").classList.remove('error');
              }, 300);
            }*/
        } else {


            this.boatController.boatLessonPost('topic', new Date(this.date.concat(" " + createEditForm.time)), this.seats, "string", parseInt(id.toString()), true, this.token).then(response => {
                response.json().then(res => {
                   
                    for (let i = 0; i < this.teachers.length; i++) {
                        if (this.teachers[i].id == id) {
                            res.teacher_first_last_name = this.teachers[i].first_name + " " + this.teachers[i].last_name;
                        }
                    }
                    this.calendarEvents = this.calendarEvents.concat(res);
                    let key: number = this.calendarEvents.length - 1;
                    this.calendarEvents[key].title = '0 Prenotati';
                    this.calendarEvents[key].description = '0 prenotati 0 in lista capitano: ' + res.teacher_id;
                    this.calendarEvents[key].backgroundColor = this.tertiaryColor.color;

                    document.getElementById("appCalendar").classList.remove("noClick");
                    document.getElementById("createEditLesson").style.display = "none";
                })
            }).catch(error => {
                console.log("Errore create event controller");
                window.location.replace('error');
            });

        }

    }
    closeCreate() {
        document.getElementById("appCalendar").classList.remove("noClick");
        document.getElementById("createEditLesson").style.display = "none";

        if (this.modifiedEvent) window.location.replace('booking');

    }
    confirmLessonController() {
        if (confirm("Sei sicuro di voler confermare la lezione? ")) {
            this.boatController.confirmBoatLessonPostById(parseInt(this.idLastClickedEvent), this.token);
            document.getElementById("appCalendar").classList.remove("noClick");
            document.getElementById("createEditLesson").style.display = "none";
            alert("Evento confermato");
        }
    }
    deleteEvent() {
        if (confirm('Sei sicuro di voler eliminare l\'evento?')) {
            this.boatController.boatLessonDeleteById(parseInt(this.idLastClickedEvent), true, this.token);
            document.getElementById("appCalendar").classList.remove("noClick");
            document.getElementById("createEditLesson").style.display = "none";
            alert("Evento eliminato");
            window.location.replace('booking');
        }
    }
    sendMessage(info: any) {
        if (confirm("Vuoi inviare il messaggio agli studenti prenotati? ")) {

            this.sendMessageController.postSendMessage(parseInt(this.idLastClickedEvent), this.token, info.messaggioBroadcast, false);
            document.getElementById("appCalendar").classList.remove("noClick");
            document.getElementById("createEditLesson").style.display = "none";
            alert("Messaggio inviato");
        }
    }
    takeColor() {
       
        this.colorSettings.getSettings(this.token).then((response: any) => {
            response.json().then(res => {
             
                this.primaryColor = res.indicators[0];
                this.secondaryColor = res.indicators[1];
                this.tertiaryColor = res.indicators[2];
                this.reservedColor.list = res.reservation_status[1].list;
                this.reservedColor.reserved = res.reservation_status[0].reserved;
                this.reservedColor.none = res.reservation_status[2].none;
                this.seats = parseInt(res.seats);
                this.takeBoatLesson();

            });

        }).catch(error => {
            console.log("Errore take color");
            window.location.replace('error');
        });


    }


    popOverClickPrenotazione(info: any) {

        this.boatQueueController.ReservedBoatPost(parseInt(this.idLastClickedEvent), this.token).then(response => {
            window.location.replace('booking');
        }).catch(error => {
            console.log("Errore popOverClickPrenotazione");
            window.location.replace('error');
        });
    }

    popOverClickCancellaPrenotazione(info: any) {

        this.boatQueueController.ReservedBoatDelete(parseInt(this.idLastClickedEvent), this.token).then(response => {
            window.location.replace('booking');
        })
            .catch(error => {
                console.log('Errore popOverClickCancellaPrenotazione');
                window.location.replace('error');
            });


    }

    popOverClickEliminaDaCoda(info: any) {

        this.boatQueueController.queueBoatLessonDelete(parseInt(this.idLastClickedEvent), this.token).then(response => {
            window.location.replace('booking');
        })
            .catch(error => {
                console.log('Errore  popOverClickEliminaDaCoda');
                window.location.replace('error');
            });


    }
    takeLists() {
        this.studentController.boatLessons(this.token).then(response => {
            response.json().then(res => {
                for (let index in res.reserved) {
                    this.idLPrenotate.push(res.reserved[index].id);
                }
                for (let index in res.queued) {
                    this.idLInCoda.push(res.queued[index].id);
                }

            })

        }).catch(error => {
            console.log("Errore  takeLists");
            window.location.replace('error');
        });
    }
    cancelEvent() {
        if (confirm('Sei sicuro di voler annullare l\'evento?')) {
            this.boatController.cancelBoatLessonPostById(parseInt(this.idLastClickedEvent), this.token);
            document.getElementById("appCalendar").classList.remove("noClick");
            document.getElementById("createEditLesson").style.display = "none";
            alert("Evento annullato");
            window.location.replace('booking');
        }
    }
    deleteReserved(student: any) {
        if (confirm("Vuoi eliminare lo studente selezionato dai prenotati?")) {

            let newReserved: any = [];
            let index: number = 0;
            for (let i = 0; i < this.studentiReserved.length; i++) {
                if (this.studentiReserved[i].id != student.id) {
                    newReserved[index] = this.studentiReserved[i];
                    index = index + 1;
                }
            }
            this.studentiReserved = newReserved;
            this.modifiedEvent = true;
            this.boatStudentsController.patchBoatLessonStudentsById(parseInt(this.idLastClickedEvent), this.takeReservedId(), this.takeQueueId(), this.token);


        }
    }
    deleteQueued(student: any) {
        if (confirm("Vuoi eliminare lo studente selezionato dalla lista d\'attesa?")) {

            let newQueue: any = [];
            let index: number = 0;
            for (let i = 0; i < this.studentiQueue.length; i++) {
                if (this.studentiQueue[i].id != student.id) {
                    newQueue[index] = this.studentiQueue[i];
                    index = index + 1;
                }
            }
            this.studentiQueue = newQueue;
            this.modifiedEvent = true;
            this.boatStudentsController.patchBoatLessonStudentsById(parseInt(this.idLastClickedEvent), this.takeReservedId(), this.takeQueueId(), this.token);


        }
    }
    addStudentsReserved() {
        document.getElementById("createEditLesson").classList.add("noClick");

        this.rowDataReserved = [];
        this.studentsController.studentsGet(null, this.token).then(response => {
            response.json().then(res => {
                let rowData: any = [];
                let students: any = res;
                let index: number = 0;
                let flagForIndex = true;

                for (let i = 0; i < students.length; i++) {

                    for (let j = 0; j < this.studentiReserved.length; j++) {

                        if (students[i].id == this.studentiReserved[j].id) {
                            flagForIndex = false;
                            break;
                        }
                        else {

                            flagForIndex = true;
                        }
                    }
                    if (flagForIndex) {
                        rowData[index] = students[i];
                        index = index + 1;
                    }

                }

                let data = rowData;
                flagForIndex = false;
                if (this.studentiQueue.length > 0) rowData = [];
                index = 0;
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < this.studentiQueue.length; j++) {
                        if (data[i].id == this.studentiQueue[j].id) {
                            flagForIndex = false;
                            break;
                        }
                        else {

                            flagForIndex = true;
                        }
                    }
                    if (flagForIndex) {
                        rowData[index] = data[i];
                        index = index + 1;
                    }
                }


                this.rowDataReserved = rowData;

                this.addStudents = true;
            })
        })
    }

    addStudentsList() {
        document.getElementById("createEditLesson").classList.add("noClick");
        this.rowDataQueue = [];
        this.studentsController.studentsGet(null, this.token).then(response => {
            response.json().then(res => {
                let rowData: any = [];
                let students: any = res;
                let index: number = 0;
                let flagForIndex = true;

                for (let i = 0; i < students.length; i++) {

                    for (let j = 0; j < this.studentiQueue.length; j++) {

                        if (students[i].id == this.studentiQueue[j].id) {
                            flagForIndex = false;
                            break;
                        }
                        else {

                            flagForIndex = true;
                        }
                    }
                    if (flagForIndex) {
                        rowData[index] = students[i];
                        index = index + 1;
                    }

                }

                let data = rowData;
                if (this.studentiReserved.length > 0) rowData = [];
                flagForIndex = false;
                index = 0;
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < this.studentiReserved.length; j++) {
                        if (data[i].id == this.studentiReserved[j].id) {
                            flagForIndex = false;
                            break;
                        }
                        else {

                            flagForIndex = true;
                        }
                    }
                    if (flagForIndex) {
                        rowData[index] = data[i];
                        index = index + 1;
                    }
                }
                

                this.rowDataQueue = rowData;
                this.addStudentsQueue = true;
                
            })
        })
    }
    closeAddStudents() {
        if (confirm('Se continui tutte le modifiche andranno perse. Sei sicuro di voler uscire senza effettuare modifiche?')) {
            this.addStudents = false;
            this.addStudentsQueue = false;
            document.getElementById("createEditLesson").classList.remove("noClick");
        }

    }
    setSelectedRowsReserved() {

        let selectedReserved = this.gridOptionsReserved.api.getSelectedRows();

        if (selectedReserved.length + this.studentiReserved.length > this.seats) {
            alert("Il numero dei prenotati e quello dei selezionati supera il numero dei posti disponibili");
        }
        else {
            if (confirm('Sei sicuro di voler aggiungere gli studenti selezionati con quelli prenotati all\'evento?')) {
                this.modifiedEvent = true;
                document.getElementById("createEditLesson").classList.remove("noClick");
                let selected: number[] = [];
                let reserved: number[] = [];
                let queue: number[] = this.takeQueueId();
                for (let i = 0; i < selectedReserved.length; i++) {
                    selected[i] = parseInt(selectedReserved[i].id);
                }
                for (let i = 0; i < this.studentiReserved.length; i++) {
                    reserved[i] = parseInt(this.studentiReserved[i].id);
                }
                this.studentiReserved = this.studentiReserved.concat(selectedReserved);

                this.boatStudentsController.patchBoatLessonStudentsById(parseInt(this.idLastClickedEvent), selected.concat(reserved), queue, this.token);

                this.addStudents = false;
                alert("Evento aggiornato");

            }


        }
    }
    setSelectedRowsList() {



        let selectedQueue = this.gridOptionsQueue.api.getSelectedRows();



        if (confirm('Sei sicuro di voler aggiungere gli studenti selezionati con quelli in lista all\'evento?')) {
            this.modifiedEvent = true;
            document.getElementById("createEditLesson").classList.remove("noClick");
            let selected: number[] = [];
            let queue: number[] = [];
            let reserved: number[] = this.takeReservedId();
            for (let i = 0; i < selectedQueue.length; i++) {
                selected[i] = parseInt(selectedQueue[i].id);
            }
            for (let i = 0; i < this.studentiQueue.length; i++) {
                queue[i] = parseInt(this.studentiQueue[i].id);
            }
            this.studentiQueue = this.studentiQueue.concat(selectedQueue);

            this.boatStudentsController.patchBoatLessonStudentsById(parseInt(this.idLastClickedEvent), reserved, selected.concat(queue), this.token);

            this.addStudentsQueue = false;
            alert("Evento aggiornato");

        }


    }



}



