import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Student } from '../../../models/student';
import { ReportStudentsResponse } from '../../../models/report-allievi/students-response';

@Injectable()
export class NgxdatatableService {
    constructor(private http: HttpClient) {}

    /**
     * @description get students list
     * @param url boat-lessons or sessioni-in-aula
     * @param id of session
     */
    getStudents(url: string, id: number): Observable<Array<Student[]>> {
        return this.http.get<Array<Student[]>>(environment.apiUrl + `${url}/${id}/students`).pipe(
            map(value => {
                if (value['reserved']) {
                    return [value['reserved'], value['present']];
                } else {
                    return [value];
                }
            })
        );
    }

    sendTelegramRegistration(istruttoreID: number): Observable<boolean> {
        return this.http.post<boolean>(environment.apiUrl + `teachers/${istruttoreID}/send-telegram-registration`, istruttoreID);
    }

    sendSessioneLinkByMail(istruttoreID: number): Observable<boolean> {
        return this.http.post<boolean>(environment.apiUrl + `boat-lessons/${istruttoreID}/new-lesson-mail`, istruttoreID);
    }

    sendSessioneLinkByTelegram(istruttoreID: number): Observable<boolean> {
        return this.http.post<boolean>(environment.apiUrl + `boat-lessons/${istruttoreID}/new-lesson-telegram`, istruttoreID);
    }

    getReport(id: number): Observable<any> {
        return this.http.post<ReportStudentsResponse>(environment.apiUrl + 'students/reports', { student_ids: [id] }).pipe(
            map(value => {
                value.students.forEach(allievo => {
                    allievo.class_lesson.sort((a, b) => a.date.localeCompare(b.date));
                    allievo.boat_lessons.sort((a, b) => a.date.localeCompare(b.date));
                });
                return value;
            })
        );
    }

    getPDFAllievi(ids: number[]): Observable<Blob> {
        return this.http.post<Blob>(
            environment.apiUrl + '/reports/students',
            { student_ids: ids },
            { headers: {}, observe: 'body', params: {}, reportProgress: false, responseType: 'blob' as 'json' }
        );
    }

    getPDFsia(ids: number[]): Observable<Blob> {
        return this.http.post<Blob>(environment.apiUrl + '/reports/class-session', ids, { headers: {}, observe: 'body', params: {}, reportProgress: false, responseType: 'blob' as 'json' });
    }
}
