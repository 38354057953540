import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StudentsControllerService {
    private url = environment.booking_apiUrl + 'students';

  constructor() {}

  studentsCount(token: string) {
    var richiesta = new Request(this.url + '/count', {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentsReports(ids: Array<number>, token: string) {
    var richiesta = new Request(this.url + '/reports', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        student_ids: ids
      })
    });

    return fetch(richiesta);
  }

  studentPut(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'put',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentPatch(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'patch',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentGet(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentDelete(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'delete',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentsPost(
    deleted_at: Date,
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    class_year: string,
    class_type_id: number,
    telegram_challenge: string,
    telegram_chat_id: number,
    token: string
  ) {
    if (deleted_at != null) {
      var richiesta = new Request(this.url, {
        method: 'put',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          deleted_at: deleted_at,
          id: id,
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          class_year: class_year,
          class_type_id: class_type_id,
          telegram_challenge: telegram_challenge,
          telegram_chat_id: telegram_chat_id
        })
      });
    } else {
      var richiesta = new Request(this.url, {
        method: 'put',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          id: id,
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          class_year: class_year,
          class_type_id: class_type_id,
          telegram_challenge: telegram_challenge,
          telegram_chat_id: telegram_chat_id
        })
      });
    }

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentsPatch(
    deleted_at: Date,
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    class_year: string,
    class_type_id: number,
    telegram_challenge: string,
    telegram_chat_id: number,
    where: object,
    token: string
  ) {
    if (deleted_at != null) {
      if (where == null) {
        var richiesta = new Request(this.url, {
          method: 'patch',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }),
          body: JSON.stringify({
            deleted_at: deleted_at,
            id: id,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            class_year: class_year,
            class_type_id: class_type_id,
            telegram_challenge: telegram_challenge,
            telegram_chat_id: telegram_chat_id
          })
        });
      } else {
        var richiesta = new Request(this.url + '?where=' + where, {
          method: 'patch',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }),
          body: JSON.stringify({
            deleted_at: deleted_at,
            id: id,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            class_year: class_year,
            class_type_id: class_type_id,
            telegram_challenge: telegram_challenge,
            telegram_chat_id: telegram_chat_id
          })
        });
      }
    } else {
      if (where == null) {
        var richiesta = new Request(this.url, {
          method: 'patch',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }),
          body: JSON.stringify({
            id: id,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            class_year: class_year,
            class_type_id: class_type_id,
            telegram_challenge: telegram_challenge,
            telegram_chat_id: telegram_chat_id
          })
        });
      } else {
        var richiesta = new Request(this.url + '?where=' + where, {
          method: 'patch',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }),
          body: JSON.stringify({
            id: id,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            class_year: class_year,
            class_type_id: class_type_id,
            telegram_challenge: telegram_challenge,
            telegram_chat_id: telegram_chat_id
          })
        });
      }
    }

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  studentsGet(filter: object, token: string) {
    if (filter == null) {
      var richiesta = new Request(this.url, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    } else {
      var richiesta = new Request(this.url + '?filter=' + filter, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    }

    return fetch(richiesta);
  }
}
