import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsControllerService {
    private url = environment.booking_apiUrl + 'settings';

  constructor() {}

  getSettings(token: string) {
    var richiesta = new Request(this.url, {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta);
  }
}
