import { Component, OnInit, Inject } from '@angular/core';

import { UserblockService } from './userblock.service';
import { User } from '../../../../models/user';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: User;
  
    constructor(public userblockService: UserblockService,
        @Inject(LOCAL_STORAGE) private storage: StorageService) {
        this.user = this.storage.get('user');
        this.user.photo = 'assets/img/logo.jpg';
      
    }

    ngOnInit() {}

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }
}
