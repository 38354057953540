import { CourseType } from './course-type';

export class Student {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    class_type_name: string;
    class_type_id: number;
    class_year: string;

    constructor() {
        this.id = null;
        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.phone = null;
        this.class_type_name = null;
        this.class_type_id = -1;
        this.class_year = null;
    }
}
