import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendMessagesControllerService {
    private url = environment.booking_apiUrl + '';

  constructor() {}

  postSendMessage(id: number, token: string, message: string, all: boolean) {
    if (all == false) {
      var richiesta = new Request(this.url + 'boat-lessons/' + id + '/send-message', {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          body: message
        })
      });
    } else {
      var richiesta = new Request(this.url + 'boat-lessons/' + id + '/send-message?recipient_type=queued,present,reserved', {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }),
        body: JSON.stringify({
          body: message
        })
      });
    }
    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log('Errore send message');
        window.location.replace('error');
      });
  }

  postNewLessonMail(id: number, token: string): any {
    var richiesta = new Request(this.url + 'boat-lessons/' + id + '/new-lesson-mail', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  postNewLessonTelegram(id: number, token: string): any {
    var richiesta = new Request(this.url + 'boat-lessons/' + id + '/new-lesson-telegram', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  postSendTelegramRegistrationStudents(id: number, token: string): any {
    var richiesta = new Request(this.url + 'students/' + id + '/send-telegram-registration', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  postSendTelegramRegistrationTeachers(id: number, token: string): any {
    var richiesta = new Request(this.url + 'teachers/' + id + '/send-telegram-registration', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }
}
