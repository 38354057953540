import { Component, OnInit, Injector, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

import { MenuService } from '../../../core/menu/menu.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { menu, menuStudent } from '../../../routes/menu';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    menuItems: Array<any>;

    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;
    level: any;

    constructor(public menuService: MenuService,
        public settings: SettingsService, public injector: Injector,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private router: Router) {
        this.level = this.storage.get('user').levels[0];
     
        if (this.level == 'Student' || this.level == 'Teacher' ) {
          
            this.menuItems = menuStudent;
        }
        else this.menuItems = menu;
    }

    ngOnInit() {
       
        this.router = this.injector.get(Router);

        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.setLayoutSetting('asideToggled', false);
        });

        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();
    }

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.setLayoutSetting('asideToggled', false);
            }
        });
    }

    ngOnDestroy() {
        if (this.$doc) {
            this.$doc.off(this.sbclickEvent);
        }
    }

    toggleSubmenuClick(event) {

        event.preventDefault();

        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

            const ul = $(event.currentTarget.nextElementSibling);

            // hide other submenus
            const parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                const $el = $(el);
                // if element is not a parent or self ul
                if (el !== parentNav[0] && el !== ul[0]) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            const ulHeight = ul.css('height');
            if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
                this.closeMenu(ul);
            } else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.css('height', 'auto').off('transitionend');
                }).css('height', ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }

        }

    }

    // Close menu collapsing height
    closeMenu(elem) {
        elem.css('height', elem[0].scrollHeight); // set height
        elem.css('height', 0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        const self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            const ul = $(event.currentTarget.nextElementSibling);
            const anchor = $(event.currentTarget);

            if (!ul.length) {
                return; // if not submenu return
            }

            const $aside = $('.aside-container');
            const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            const $sidebar = $asideInner.children('.sidebar');
            const mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            const itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            const floatingNav = ul.clone().appendTo($aside);
            const vwHeight = document.body.clientHeight;

            // let itemTop = anchor.position().top || anchor.offset().top;

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .addClass('nav-floating')
                .css({
                    position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => {
                    floatingNav.remove();
                })
                .find('a').on('click', function (e) {
                e.preventDefault(); // prevents page reload on click
                // get the exact route path to navigate
                const routeTo = $(this).attr('route');
                if (routeTo) {
                    self.router.navigate([routeTo]);
                }
            });

            this.listenForExternalClicks();

        }

    }

    listenForExternalClicks() {
        const $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }

    isSidebarCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    isEnabledHover() {
        return this.settings.getLayoutSetting('asideHover');
    }
}
