import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BoatLessonStudentControllerService {
    private url = environment.booking_apiUrl + 'boat-lessons';

  constructor() {}

  getBoatLessonStudents(filter: object, token: string) {
    if (filter == null) {
      var richiesta = new Request(this.url + '/students', {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    } else {
      var richiesta = new Request(this.url + '/students?filter=' + filter, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    }

    return fetch(richiesta);
  }

    postBoatLessonStudentsById(id: number, reserved: Array<number>, present: Array<number>, queued: Array<number>, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/students', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        reserved: reserved,
          present: present,
          queued: queued
      })
    });

    fetch(richiesta)
      .then(response => {
        response.json().then(res => {
          console.log(res);
        });
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

    patchBoatLessonStudentsById(id: number, reserved: Array<number>,  queued: Array<number>, token: string) {
        var richiesta = new Request(this.url + '/' + id + '/students', {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }),
            body: JSON.stringify({
                reserved: reserved,
               
                queued: queued
            })
        });

        fetch(richiesta)
            .then(response => {
                response.json().then(res => {
                    console.log(res);
                });
            })
            .catch(error => console.log('Si è verificato un errore!'));
    }

  getBoatLessonStudentsById(id: number, token: string): Promise<any> {
    var richiesta = new Request(this.url + '/' + id + '/students', {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta);
  }
}
