import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { StudentPP } from '../../../models/student-pp';
import { NgxdatatableService } from './ngxdatatable.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ReportStudentResponse } from '../../../models/report-allievi/student-response';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-ngxdatatable',
    templateUrl: './ngxdatatable.component.html',
    styleUrls: ['./ngxdatatable.component.scss']
})
export class NgxdatatableComponent implements OnInit, OnChanges, OnDestroy {
    tTableType = TableType;

    @Input() rows: any[] = [];
    @Input() columns: any[] = [];
    @Input() expandable: boolean;
    @Input() tableType: TableType;
    @Input() sorts: any[] = [];
    @Input() needsDateFilter = false;
    @ViewChild(DatatableComponent) table: DatatableComponent;
    studentsPP: { [id: number]: Array<StudentPP> } = {};
    studentsReport: { [id: number]: ReportStudentResponse } = {};
    temp: any[] = [];
    subs: Subscription;
    progressStatus = false;
    modalRef: BsModalRef;
    rowId: number;
    filterText: string = '';
    filterDateFrom: Date = null;
    filterDateTo: Date = null;
    showPresenti: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar, private service: NgxdatatableService, private modalService: BsModalService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.rows) {
            this.temp = [...this.rows];
        }
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

    clearFilters() {
        this.filterText = '';
        this.filterDateFrom = null;
        this.filterDateTo = null;
        this.updateFilter();
    }

    updateFilterFrom(event: MatDatepickerInputEvent<Date>) {
        this.filterDateFrom = event.value;
        this.updateFilter();
    }

    updateFilterTo(event: MatDatepickerInputEvent<Date>) {
        this.filterDateTo = event.value;
        this.updateFilter();
    }

    updateFilterText(event): void {
        this.filterText = event.target.value.toLowerCase();
        this.updateFilter();
    }
    /**
     * @description filter for search field
     * @param event input event
     */
    updateFilter(): void {
        // filter on all text fields
        const keys = this.temp.length > 0 ? Object.keys(this.temp[0]) : Array();
        this.rows = this.temp.filter(d => {
            return keys.some(key => {
                if (typeof d[key] === 'string') {
                    const intDate = Date.parse(d[key]);
                    if (isNaN(intDate)) {
                        return d[key].toLowerCase().includes(this.filterText);
                    }
                } else {
                    return false;
                }
            });
        });

        // filter on all data fields
        this.rows = this.rows.filter(d => {
            return keys.some(key => {
                if (typeof d[key] === 'string') {
                    const intDate = Date.parse(d[key]);
                    if (!isNaN(intDate)) {
                        const date = new Date(intDate);
                        return (this.filterDateFrom == null || this.filterDateFrom <= date) && (this.filterDateTo == null || date <= this.filterDateTo);
                    }
                } else {
                    return false;
                }
            });
        });

        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    /**
     * @description row click event redirect to edit component
     * @param event ngx-datatable event type(https://swimlane.gitbook.io/ngx-datatable/api/table/outputs)
     */
    onRowClick(event: any): void {
        if (event.type === 'click') {
            if (event.row.telegram_chat_id && event.column.prop === 'telegram_chat_id') {
                // do nothing
            } else if (event.column.prop === 'link') {
                // do nothing
            } else {
                this.router.navigate([event.row.id], { relativeTo: this.route });
            }
        }
    }

    /**
     * @description function to copy in clipboard
     * @param text to copy
     */
    copyToClickBoard(text: string): void {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            this.snackBar.open('Copied to Clipboard', 'Close', {
                duration: 2000
            });
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    /**
     * @description redirect to new
     */
    redirectNew(): void {
        this.router.navigate(['new'], { relativeTo: this.route });
    }

    toggleExpandRow(row) {
        this.table.rowDetail.toggleExpandRow(row);
        switch (this.tableType) {
            case TableType.Allievi:
                this.service.getReport(row.id).subscribe(value => {
                    value.students.forEach(allievo => {
                        this.studentsReport[allievo.student.id] = allievo;
                    });
                    // console.log(value);
                    // console.log(this.studentsReport);
                });
                break;

            default:
                this.showPresenti = row['link'];
                this.subs = this.service.getStudents(this.showPresenti ? 'boat-lessons' : 'class-lessons', row.id).subscribe(value => {
                    console.log(value[0]);
                    this.studentsPP[row.id] = [];
                    value[0].forEach(v => this.studentsPP[row.id].push(StudentPP.fromParent(v)));
                    if (value.length > 1) {
                        value[1].forEach(v => (this.studentsPP[row.id].find(s => s.id === v.id).present = true));
                    }
                    console.log(this.studentsPP);
                });
                break;
        }
    }

    // #region SendMail
    /**
     * @description open modal to confirm delete
     * @param id istruttoreId
     * @param template Modal component ng-template
     */
    openModalTelegramSendMail(id: number, template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
        this.rowId = id;
    }

    /**
     * @description on modal confirm delete request
     */
    confirmMail(): void {
        this.telegramSendMail(this.rowId);
        this.modalRef.hide();
    }

    /**
     * @description decline close modal
     */
    declineMail(): void {
        this.modalRef.hide();
    }

    telegramSendMail(id: number): void {
        this.progressStatus = true;
        this.service.sendTelegramRegistration(id).subscribe(
            () => {
                this.snackBar.open('Mail inviata', 'Close', {
                    duration: 2000
                });
                this.progressStatus = false;
            },
            () => {
                this.progressStatus = false;
            }
        );
    }
    //#endregion

    //#region SendLink
    /**
     * @description open modal to confirm delete
     * @param id istruttoreId
     * @param template Modal component ng-template
     */
    openModalTelegramSendLink(id: number, template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-md' });
        this.rowId = id;
    }

    /**
     * @description on modal confirm delete request
     */
    confirmLinkMail(): void {
        console.log('confirmLinkMail', this.rowId);
        this.sendLinkMail(this.rowId);
        this.modalRef.hide();
    }

    confirmLinkTelegram(): void {
        this.sendLinkTelegram(this.rowId);
        this.modalRef.hide();
    }

    /**
     * @description decline close modal
     */
    declineLink(): void {
        this.modalRef.hide();
    }

    sendLinkMail(id: number): void {
        console.log('sendLinkMail', id);
        this.progressStatus = true;
        this.service.sendSessioneLinkByMail(id).subscribe(
            () => {
                this.snackBar.open('Link inviato', 'Close', {
                    duration: 2000
                });
                this.progressStatus = false;
            },
            () => {
                this.progressStatus = false;
            }
        );
    }

    sendLinkTelegram(id: number): void {
        this.progressStatus = true;
        this.service.sendSessioneLinkByTelegram(id).subscribe(
            () => {
                this.snackBar.open('Link inviato', 'Close', {
                    duration: 2000
                });
                this.progressStatus = false;
            },
            () => {
                this.progressStatus = false;
            }
        );
    }
    //#endregion

    //#region PDF
    downloadPDF(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    }

    declinePDF(): void {
        this.modalRef.hide();
    }

    downloadPDFOK() {
        this.modalRef.hide();
        this.progressStatus = true;
        const ids = [];
        this.rows.forEach(row => {
            ids.push(row.id);
        });
        switch (this.tableType) {
            case TableType.Allievi:
                this.service.getPDFAllievi(ids).subscribe(value => {
                    FileSaver.saveAs(value, 'allievi.pdf');
                    this.progressStatus = false;
                });
                break;
            case TableType.SiA:
                this.service.getPDFsia(ids).subscribe(value => {
                    FileSaver.saveAs(value, 'sia.pdf');
                    this.progressStatus = false;
                });
                break;
            default:
                break;
        }
    }
    //endregion
}

export enum TableType {
    Allievi,
    SiA,
    SiB
}
