import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-booking',
    templateUrl: './booking.component.html',
    styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
    bookingUrl: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {
      //  this.bookingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.booking_url);
    }

    ngOnInit() {}
}
