import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StudentsBoatLessonControllerService {
    private url = environment.booking_apiUrl + 'students/';

  constructor() {}

  //Solo studenti no admin, teacher
  boatLessonsDetails(token: string) {
    var richiesta = new Request(this.url + 'boat-lessons/details', {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
        response.json().then(res => {
          console.log(res);
        });
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  //Solo studenti no admin, teacher
  boatLessons(token: string) {
    var richiesta = new Request(this.url + 'boat-lessons', {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta);
  }
}
