import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class UtilityService {
    constructor() {}

    /**
     * @description dynamic FormGroup constructor
     * @param obj which keys are FormGroup keys, values FormControls
     */
    static constructForm<T>(obj: T, blacklistFields?: string[]): FormGroup {
        blacklistFields = blacklistFields || Array();
        blacklistFields.push('deleted_at');
        let keys: string[] = Object.keys(obj);
        keys = keys.filter(val => {
            return val !== 'id' && !blacklistFields.some(bf => bf === val);
        });

        const group: { [key: string]: FormControl } = {};

        keys.forEach(question => {
            group[question] = new FormControl(obj ? obj[question] : '', Validators[question === 'email' ? 'email' : 'required']);
        });
        return new FormGroup(group);
    }

    static extractTimeFromString(s: string): string {
        const iT = s.indexOf('T');
        return s.slice(iT + 1, iT + 6);
    }

    static addTimeToDate(date: Date | string, selectedTime: string): string {
        if (date instanceof Date) {
            date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        }
        date = date.substring(0, date.indexOf('T')) + 'T' + selectedTime + ':00Z';
        return date;
    }

    static possibleTimesBL(): string[] {
        const out = [];
        out.push('09:00');
        out.push('13:30');
        out.push('--:--');
        for (let h = 8; h <= 20; h++) {
            for (let m = 0; m < 60; m = m + 30) {
                out.push(('0' + h).slice(-2) + ':' + ('0' + m).slice(-2));
            }
        }
        return out;
    }

    static possibleTimesCL(): string[] {
        const out = [];
        out.push('09:30');
        out.push('15:00');
        out.push('19:00');
        out.push('--:--');
        for (let h = 8; h <= 20; h++) {
            for (let m = 0; m < 60; m = m + 30) {
                out.push(('0' + h).slice(-2) + ':' + ('0' + m).slice(-2));
            }
        }
        return out;
    }
}
