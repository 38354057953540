const Trainees = {
    text: 'Allievi',
    link: '/allievi',
    icon: 'icon-people',
    translate: 'menu.Trainees'
};

const Trainers = {
    text: 'Istruttori',
    link: '/istruttori',
    icon: 'icon-user',
    translate: 'menu.Trainers'
};

const ClassSession = {
    text: 'Sessioni In Aula',
    link: '/sessioni-in-aula',
    icon: 'icon-graduation',
    translate: 'menu.ClassSession'
};

const BoatSession = {
    text: 'Sessioni In Barca',
    link: '/sessioni-in-barca',
    icon: 'icon-anchor',
    translate: 'menu.BoatSession'
};

const Booking = {
    text: 'Booking',
    link: '/booking',
    icon: 'icon-calendar'
    // translate: 'menu.BoatSession'
};
export const menuStudent = [Booking];
export const menu = [Booking, ClassSession, BoatSession, Trainees, Trainers];
