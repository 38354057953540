import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ProgressbarConfig } from 'ngx-bootstrap';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { StateService } from '../../../core/state/state.service';
import { UtilityService } from '../../../core/utility/utility.service';
import { CourseType } from '../../../models/course-type';

export function getProgressbarConfig(): ProgressbarConfig {
    return Object.assign(new ProgressbarConfig(), { animate: true, striped: true, max: 100 });
}

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    providers: [{ provide: ProgressbarConfig, useFactory: getProgressbarConfig }]
})
export class EditComponent<T> implements OnInit, OnDestroy {
    id: number | undefined;
    data: T;
    form: FormGroup;
    inputsArray: string[];
    subs: Subject<any>;
    progressStatus = false;
    modalRef: BsModalRef;
    courseTypes: Observable<CourseType[]>;
    blacklistFields = ['telegram_challenge', 'telegram_chat_id', 'class_type_name', 'class_type'];

    @Input() component: string;
    @Input() endpoint: string;
    @Input() obj: T;

    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private state: StateService,
        private modalService: BsModalService
    ) {
        this.id = +this.route.snapshot.params.id;
        this.subs = new Subject<any>();
        this.courseTypes = this.getCourseTypes();
    }

    ngOnInit() {
        if (this.id) {
            this.http
                .get<T>(environment.apiUrl + this.endpoint + `/${this.id}`)
                .pipe(takeUntil(this.subs))
                .subscribe(
                    (res: T) => {
                        this.data = res;
                        this.form = UtilityService.constructForm<T>(this.data, this.blacklistFields);
                        this.inputsArray = Object.keys(this.form.value);
                    },
                    error1 => {
                        if (error1.status === 404) {
                            this.router.navigate(['/404']);
                        }
                    }
                );
        } else {
            this.form = UtilityService.constructForm<T>(this.obj, this.blacklistFields);
            this.inputsArray = Object.keys(this.form.value);
        }
    }

    ngOnDestroy(): void {
        this.subs.next();
        this.subs.complete();
    }

    /**
     * @description save changes to the api
     */
    onSubmit(): void {
        if (this.form.valid) {
            this.progressStatus = true;
            if (this.id) {
                this.http
                    .put<T>(environment.apiUrl + this.endpoint + `/${this.id}`, this.form.value)
                    .pipe(takeUntil(this.subs))
                    .subscribe(
                        res => {
                            this.progressStatus = false;
                            this.updateArray(this.endpoint, this.id, { ...this.form.value, id: this.id });
                            this.snackBar.open('Successfully Updated', 'Close', {
                                duration: 2000
                            });

                            this.router.navigate([`../`], { relativeTo: this.route });
                        },
                        error1 => (this.progressStatus = false)
                    );
            } else {
                this.http
                    .post<T>(environment.apiUrl + this.endpoint, this.form.value)
                    .pipe(takeUntil(this.subs))
                    .subscribe(
                        res => {
                            this.progressStatus = false;
                            this.pushToArray(res);
                            this.snackBar.open('Successfully Created', 'Close', {
                                duration: 2000
                            });
                            this.router.navigate([`../`], { relativeTo: this.route });
                        },
                        error1 => (this.progressStatus = false)
                    );
            }
        }
    }

    /**
     * @description delete student
     */
    onDelete(): void {
        if (this.id) {
            this.progressStatus = true;

            this.http
                .delete(environment.apiUrl + this.endpoint + `/${this.id}`)
                .pipe(takeUntil(this.subs))
                .subscribe(
                    res => {
                        this.progressStatus = false;

                        this.state.state[this.endpoint] = this.deleteArrayElement(this.state.state[this.endpoint], this.id);

                        this.snackBar.open('Successfully Deleted', 'Close', {
                            duration: 2000
                        });

                        this.router.navigate([`../`], { relativeTo: this.route });
                    },
                    error1 => (this.progressStatus = false)
                );
        }
    }

    /**
     * @description update the array by id in session storage
     * @param component the name of array
     * @param id of the array index
     * @param newData of the index
     */
    updateArray(component: string, id: number, newData: T): void {
        const arr = this.state.state[component];
        if (arr) {
            this.state.state[component] = arr.map(value => (value.id === id ? newData : value));
        }
    }

    /**
     * @description push to array in state
     * @param item in the array
     */
    pushToArray(item: T): void {
        const newArr = this.state.state[this.endpoint];
        newArr.push(item);
        this.state.state[this.endpoint] = newArr;
    }

    /**
     * @description delet array element
     * @param arr in storage
     * @param id of the element
     */
    deleteArrayElement(arr: T[], id: number): T[] {
        let index;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i]['id'] === id) {
                index = i;
            }
        }
        arr.splice(index, 1);
        return arr;
    }

    /**
     * @description navigate back to table
     */
    back(): void {
        this.router.navigate([`../`], { relativeTo: this.route });
    }

    /**
     * @description open modal to confirm delete
     * @param template Modal component ng-template
     */
    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    }

    /**
     * @description on modal confirm delete request
     */
    confirm(): void {
        this.onDelete();
        this.modalRef.hide();
    }

    /**
     * @description decline close modal
     */
    decline(): void {
        this.modalRef.hide();
    }

    /**
     * @description GET course types
     */
    getCourseTypes(): Observable<CourseType[]> {
        return this.http.get<CourseType[]>(environment.apiUrl + 'class-type');
    }
}
