import { Student } from './student';

export class StudentPP extends Student {
    reserved = false;
    present = false;

    constructor() {
        super();
    }

    static fromParent(s: Student): StudentPP {
        const out = Object.assign(s);
        out.reserved = false;
        out.present = false;
        return out;
    }
}
