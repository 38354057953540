import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BoatLessonControllerService {
    private url = environment.booking_apiUrl + 'boat-lessons';

  constructor() {}

  //where è un json contenente le info per le quali fare la cernita delle lezioni. Il metodo torna il conto delle lezioni con le caratteristiche contenute nel where
  boatLessonCount(where: object, token: string) {
    if (where == null) {
      var richiesta = new Request(this.url + '/count', {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    } else {
      var richiesta = new Request(this.url + '/count?where=' + where, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    }
    fetch(richiesta)
      .then(response => {
        console.log(response);
        response.json().then(res => {
          console.log(res);
        });
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  //Add ?notify=true to notify Students Reserved
  boatLessonPutById(id: number, topic: string, date: Date, seats: number, link: string, teacher_id: number, notify: boolean, token: string) {
    var richiesta = new Request(this.url + '/' + id + '?notify=' + notify, {
      method: 'put',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        topic: topic,
        date: date,
        seats: seats,
        link: link,
        teacher_id: teacher_id
      })
    });

    return fetch(richiesta);
  }

  confirmBoatLessonPostById(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/confirm', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log('Errore confirm lesson controller');
        window.location.replace('error');
      });
  }

  //Add ?notify=true to notify Students Reserved... Non è accessibile a noi poveri umani
  boatLessonPatchById(id: number, topic: string, date: Date, seats: number, teacher_id: number, notify: boolean, token: string) {
    var richiesta = new Request(this.url + '/' + id + '?notify=' + notify, {
      method: 'patch',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        id: id,
        topic: topic,
        date: date,
        seats: seats,
        link: 'string',
        teacher_id: teacher_id
      })
    });

    return fetch(richiesta);
  }
  cancelBoatLessonPostById(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/cancel', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    });

    fetch(richiesta)
      .then(response => {})
      .catch(error => {
        console.log('Errore cancelEvent');
        window.location.replace('error');
      });
  }

  boatLessonGetById(id: number, token: string) {
    var richiesta = new Request(this.url + '/' + id, {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });

    return fetch(richiesta);
  }

  //Add ?notify=true to notify Students Reserved
  boatLessonDeleteById(id: number, notify: boolean, token: string) {
    var richiesta = new Request(this.url + '/' + id + '/?notify=' + notify, {
      method: 'delete',
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    });
    fetch(richiesta)
      .then(response => {})
      .catch(error => {
        console.log('Errore delete event ');
        window.location.replace('error');
      });
  }

  //Add ?notify=true to notify Students Reserved considerare la possibilità di aggiungere deleted_at con un date
  boatLessonPost(topic: string, date: Date, seats: number, link: string, teacher_id: number, notify: boolean, token: string) {
    let teacher: number;
    teacher = parseInt(teacher_id.toString());
    var richiesta = new Request(this.url + '?notify=' + notify, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        topic: topic,
        date: date.toJSON(),
        seats: seats,
        teacher_id: teacher_id,
        link: link
      })
    });

    return fetch(richiesta);
  }

  //Non è accessibile a noi
  boatLessonPatch(
    id: number,
    deleted_at: Date,
    topic: string,
    date: Date,
    seats: number,
    link: string,
    teacher_id: number,
    class_type_id: number,
    reminder_status: Array<string>,
    where: object,
    token: string
  ) {
    var richiesta = new Request(this.url + '?where=' + where, {
      method: 'patch',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
      body: JSON.stringify({
        topic: topic,
        date: date,
        seats: 0,
        link: 'string',
        teacher_id: 0,
        class_type_id: 0,
        reminder_status: ['string']
      })
    });

    fetch(richiesta)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  }

  boatLessonGet(filter: object, token: string) {
    if (filter == null) {
      var richiesta = new Request(this.url, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    } else {
      var richiesta = new Request(this.url + '?filter=' + filter, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        })
      });
    }

    return fetch(richiesta);
  }
}
